import { graphql, useStaticQuery } from "gatsby";

function useSettings() {
	const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle: defaultMetaTitle
          defaultDescription: defaultMetaDescription {
						text: defaultMetaDescription
					}
          defaultShareImage {
            gatsbyImageData(
							layout: FIXED # or CONSTRAINED
							formats: [JPG] # default is WEBP
							width: 1200
							height: 1200
							cropFocus: FACES
							resizingBehavior: FILL
						)
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}

export default useSettings
