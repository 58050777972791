import { useEffect } from 'react';

const isBrowser = typeof window !== "undefined"

export default function useWindowHeight() {
	useEffect(() => {
		if (isBrowser) {
			let timer;
			const updateHeight = () => {
				// timer = setTimeout(() => {
				// 	document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`);
				// 	clearTimeout(timer)
				// }, 300)
				document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`);
			}

			updateHeight()
			window.addEventListener("resize", updateHeight);

			return () => {
				clearTimeout(timer)
				window.removeEventListener("resize", updateHeight);
			}
		}
	}, [])
}
