// Dependencies
import React from "react"
// Hooks
import useWindowHeight from "../hooks/useWindowHeight"
// Components
import Meta from "../components/Meta"
// CSS
import "../css/styles.scss"

const Layout = ({ title, children }) => {

	useWindowHeight()

	return (
		<main>
			<Meta title={title} />
			{children}
		</main>
	)
}

export default Layout